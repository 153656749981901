import * as React from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'

import * as entities from '@owl-nest/entities'
import * as money from '@owl-nest/money'
import * as plume from '@ulule/owl-kit-components/next'

export type EnrichedCarouselProps = {
  cta?: React.ReactNode
  hasBackground?: boolean
  list: entities.product.SearchHitProduct[]
  title?: React.ReactNode
}

export function EnrichedCarousel({ cta, list, hasBackground, title }: EnrichedCarouselProps): React.ReactElement {
  return (
    <SectionCarouselWrapper hasBackground={hasBackground}>
      {title}
      <LargeLaptopCarousel>
        <plume.Carousel>
          {paginate(list, 4).map((page, i) => (
            <Page key={i} page={page} />
          ))}
        </plume.Carousel>
      </LargeLaptopCarousel>
      <LaptopCarousel>
        <plume.Carousel>
          {paginate(list, 3).map((page, i) => (
            <Page key={i} page={page} />
          ))}
        </plume.Carousel>
      </LaptopCarousel>
      <TabletCarousel>
        <plume.Carousel>
          {paginate(list, 2).map((page, i) => (
            <Page key={i} page={page} />
          ))}
        </plume.Carousel>
      </TabletCarousel>
      <MobileCarousel>
        <plume.Carousel>
          {paginate(list, 1).map((page, i) => (
            <Page key={i} page={page} />
          ))}
        </plume.Carousel>
      </MobileCarousel>
      {cta}
    </SectionCarouselWrapper>
  )
}

function Page({ page }: { page: entities.product.SearchHitProduct[] }): React.ReactElement {
  return (
    <Grid>
      {page.map((product) => (
        <Link key={product.title} href={product.href}>
          <plume.ProductCard
            brand={product.brand}
            criteria={product.criteria}
            originalPrice={product.compareAtPriceRange ? money.formatRange(product.compareAtPriceRange) : undefined}
            picture={product.picture}
            price={money.formatRange(product.priceRange)}
            title={product.title}
            variantInformation={(product.colors?.length ?? 0) > 1 ? `${product.colors?.length} couleurs` : undefined}
          />
        </Link>
      ))}
    </Grid>
  )
}

function paginate<T>(items: T[], size: number): T[][] {
  return items.reduce(
    (list, item, i) => {
      const lastPage = list[list.length - 1]
      lastPage.push(item)
      if (lastPage.length === size && i < items.length - 1) {
        list.push([])
      }
      return list
    },
    [[]] as T[][],
  )
}

const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, 270px);
  grid-auto-rows: 514px;
  grid-gap: 32px;

  @media screen and ${plume.BREAKPOINTS.MOBILE_M} {
    grid-template-columns: repeat(auto-fit, 296px);
  }
`

const SectionCarouselWrapper = styled.section<{
  hasBackground?: boolean
}>`
  max-width: 270px;
  margin: ${(props) => (props.hasBackground ? '60px auto 0px' : '80px auto 0px')};
  position: relative;
  padding-bottom: 56px;

  &:last-of-type {
    margin: ${(props) => (props.hasBackground ? '60px auto 0px' : '60px auto')};
  }

  @media screen and ${plume.BREAKPOINTS.MOBILE_M} {
    max-width: 296px;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    max-width: 1280px;
    padding-bottom: 52px;
    margin: ${(props) => (props.hasBackground ? '112px auto' : '60px auto')};

    &:last-of-type {
      margin: ${(props) => (props.hasBackground ? '60px auto 0px' : '60px auto')};
    }
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP} {
    max-width: 952px;
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    max-width: 1280px;
  }

  ${plume.styles.heading.L} {
    margin-bottom: 32px;
    text-align: center;

    @media screen and ${plume.BREAKPOINTS.MOBILE_L} {
      br {
        display: none;
      }
    }

    @media screen and ${plume.BREAKPOINTS.TABLET} {
      margin-bottom: 40px;
      text-align: left;
    }
  }

  ${plume.styles.card.product.Informations} {
    padding: 16px 16px 20px 16px;
  }

  ${({ hasBackground }) =>
    hasBackground &&
    css`
      &:before {
        content: '';
        display: block;
        background-color: ${plume.COLORS.PRIMARY_SAND};
        height: 500px;
        position: absolute;
        left: -100%;
        right: -100%;
        bottom: 0;
        z-index: -1;
      }
    `}
`

const MobileCarousel = styled.div`
  display: grid;
  width: 270px;
  margin: 0 auto;

  @media screen and ${plume.BREAKPOINTS.MOBILE_M} {
    width: 296px;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    display: none;
  }
`

const TabletCarousel = styled.div`
  display: none;
  width: 624px;
  margin: 0 auto;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    display: grid;
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP} {
    display: none;
  }
`

const LaptopCarousel = styled.div`
  display: none;
  width: 952px;
  margin: 0 auto;

  @media screen and ${plume.BREAKPOINTS.LAPTOP} {
    display: grid;
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    display: none;
  }
`

const LargeLaptopCarousel = styled.div`
  display: none;
  width: 1280px;
  margin: 0 auto;

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    display: grid;
  }
`
