import * as React from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import * as money from '@owl-nest/money'
import * as entities from '@boutique/entities'
import * as plume from '@ulule/owl-kit-components/next'

import { BaseHighlightedTitle, type BaseHighlightedTitleProps } from './HighlightedTitle'

type HeroProps = {
  heroData: entities.boutiqueHomepage.BoutiqueHomepage['heroData']
}

export function Hero({ heroData }: HeroProps): React.ReactElement {
  if (heroData === null) {
    return <></>
  }

  const { backgroundImage, product, text, textColor, subText, subTextColor, subTextBackgroundColor } = heroData

  return (
    <HeroWrapper>
      <ImageWrapper>
        <img
          alt={backgroundImage.x1.alt ?? ''}
          src={backgroundImage.x1.src}
          srcSet={`${backgroundImage.x1.src} 1x, ${backgroundImage.x2.src} 2x`}
        />
      </ImageWrapper>
      <HeroContent>
        <Text color={textColor}>
          {text}
          <HighlightedTitle color={subTextColor} bgColor={subTextBackgroundColor}>
            {subText}
          </HighlightedTitle>
        </Text>

        {product && (
          <Link href={product.href}>
            <plume.ProductCard
              criteria={product.criteria}
              brand={product.brand}
              cta={<CtaButton>Découvrir le produit</CtaButton>}
              originalPrice={product.compareAtPriceRange ? money.formatRange(product.compareAtPriceRange) : undefined}
              picture={product.picture}
              price={money.formatRange(product.priceRange)}
              title={product.title}
              variantInformation={(product.colors?.length ?? 0) > 1 ? `${product.colors?.length} couleurs` : undefined}
            />
          </Link>
        )}

        {product && (
          <DashedArrowWrapper>
            <DashedArrowHero />
          </DashedArrowWrapper>
        )}
      </HeroContent>
    </HeroWrapper>
  )
}

type HighlightedTitleProps = BaseHighlightedTitleProps & {
  color: string
  bgColor: string
}
const HighlightedTitle = styled(BaseHighlightedTitle)<HighlightedTitleProps>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
`

const Text = styled(plume.styles.heading.XL)<{ color: string }>`
  color: ${({ color }) => color};

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    font-size: 65px;
    line-height: 65px;
    font-weight: 900;
  }
`

const CtaButton = styled(plume.Button)`
  width: 100%;
`

const DashedArrowWrapper = styled.div`
  display: none;

  @media screen and ${plume.BREAKPOINTS.LAPTOP} {
    display: block;
    position: absolute;
    right: 40%;
    bottom: 9%;
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    bottom: 5%;
  }
`

const HeroWrapper = styled.section`
  position: relative;
  width: 100vw;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    margin-bottom: 4px;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: -1;

  img {
    min-height: 500px;
    object-fit: cover;
    width: 100%;

    @media screen and ${plume.BREAKPOINTS.TABLET} {
      min-height: 460px;
      max-height: 460px;
    }

    @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
      max-height: 550px;
    }
  }
`

const HeroContent = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 42px;

  justify-items: center;
  max-width: 1360px;
  padding: 0px 20px;
  margin: 0 auto;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    padding: 60px 20px 0 20px;
  }

  ${plume.styles.heading.XL} {
    max-width: 600px;
    padding-top: 50px;
    text-align: center;

    span {
      margin-top: 16px;
    }

    @media screen and ${plume.BREAKPOINTS.TABLET} {
      text-align: left;
    }
  }

  ${plume.ProductCard} {
    max-width: 320px;
    justify-self: center;

    ${plume.styles.card.product.Informations} {
      padding: 16px 16px 20px 16px;
    }

    @media screen and ${plume.BREAKPOINTS.MOBILE_M} {
      max-width: none;
      width: 333px;
    }

    @media screen and ${plume.BREAKPOINTS.TABLET} {
      max-width: none;
      width: 300px;
    }

    @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
      width: 364px;
    }
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    ${plume.styles.heading.XL} {
      min-width: 420px;
      padding-right: 30px;
    }
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP} {
    padding: 60px 0px 0px 60px;

    ${plume.styles.heading.XL} {
      padding-right: 0px;
      padding-top: 60px;
    }
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    ${plume.styles.heading.XL} {
      min-width: 610px;
    }
  }
`

function DashedArrowHero(props: React.SVGAttributes<SVGSymbolElement>): React.ReactElement {
  return (
    <svg width={311} height={195} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 1C1 21.3938 10.3486 42.8234 46.644 55.5284M46.644 55.5284C49.1943 56.4211 51.9003 57.1025 54.7704 57.5483C77.8305 61.13 86.6017 55.3248 88.652 47.6374C91.206 38.0614 85.7762 31.067 77.724 29.6549C70.655 28.4153 63.9129 32.6751 58.7185 37.6275C54.059 42.0699 49.351 47.9893 46.644 55.5284ZM46.644 55.5284C40.5 67.5 41.6219 84.1447 43 92C54.2194 155.951 119.811 93.9359 155.248 148.602C189.073 200.781 237.168 215.561 295 145.535"
        stroke={plume.COLORS.PRIMARY_BLACK}
        strokeWidth={2}
        strokeLinecap="square"
        strokeDasharray="2 4"
      />
      <path
        d="M309.835 130.166L309.849 130.169L309.862 130.17C310.213 130.187 310.461 130.377 310.609 130.651L310.614 130.661L310.621 130.67C310.849 130.972 310.939 131.372 310.74 131.821C308.567 136.723 306.695 141.365 305.393 146.579C305.251 147.148 304.74 147.362 304.277 147.28C304.046 147.239 303.839 147.125 303.707 146.957C303.578 146.791 303.511 146.562 303.575 146.265L303.575 146.265C304.415 142.361 305.572 138.747 306.93 135.108L306.93 135.108L306.931 135.106L307.684 132.976L307.777 132.714L307.507 132.78L304.939 133.409C301.373 134.121 297.908 134.983 294.354 136.225L294.354 136.225C294.064 136.327 293.819 136.292 293.629 136.187C293.435 136.08 293.288 135.893 293.21 135.675C293.132 135.457 293.128 135.217 293.209 135.008C293.289 134.802 293.456 134.616 293.74 134.506L293.686 134.366L293.74 134.506C298.776 132.561 303.556 131.401 308.815 130.332C308.909 130.313 309.005 130.287 309.098 130.262C309.107 130.26 309.115 130.258 309.124 130.255C309.209 130.233 309.291 130.211 309.372 130.192C309.552 130.152 309.706 130.135 309.835 130.166Z"
        fill={plume.COLORS.PRIMARY_BLACK}
        stroke={plume.COLORS.PRIMARY_WHITE}
        strokeWidth={0.3}
      />
    </svg>
  )
}
