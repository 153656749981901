import styled, { css } from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

export type BaseHighlightedTitleProps = {
  small?: boolean
}

export const BaseHighlightedTitle = styled.span<BaseHighlightedTitleProps>`
  display: inline-block;

  ${({ small }) =>
    small
      ? css`
          margin-bottom: -10px;
          padding: 0px 6px 4px;

          @media screen and ${plume.BREAKPOINTS.LAPTOP} {
            margin-bottom: -10px;
            padding: 0px 8px 6px;
          }
        `
      : css`
          margin-bottom: -6px;
          padding: 0px 10px 8px;

          @media screen and ${plume.BREAKPOINTS.LAPTOP} {
            padding: 0px 16px 12px;
            margin-bottom: 0px;
          }
        `}
`

export type HighlightedTitleProps = BaseHighlightedTitleProps & {
  color?: 'PRIMARY_RED' | 'PRIMARY_BLUE_HOVER' | 'SECONDARY_MINT'
  bgColor?: 'SECONDARY_RED_LIGHT' | 'BLUE_SHADE_3' | 'SECONDARY_GREEN_LIGHT'
}
export const HighlightedTitle = styled(BaseHighlightedTitle)<HighlightedTitleProps>`
  background-color: ${({ bgColor }) => (bgColor ? plume.COLORS[bgColor] : plume.COLORS.PRIMARY_WHITE)};
  color: ${({ color }) => (color ? plume.COLORS[color] : plume.COLORS.PRIMARY_BLACK)};
`
