import * as React from 'react'

export type JsonLd<TYPE extends string = string> = {
  '@type': TYPE
}

type JsonLdProps = {
  jsonld: JsonLd
  scriptKey?: string
}

export function JsonLdBase({ jsonld, scriptKey }: JsonLdProps) {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          ...jsonld,
          '@context': 'https://schema.org',
        }),
      }}
      key={`jsonld-${jsonld['@type']}-${scriptKey ?? ''}`}
    />
  )
}
