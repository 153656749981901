import * as React from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import * as entities from '@boutique/entities'
import * as url from '@boutique/url'
import * as plume from '@ulule/owl-kit-components/next'

import { Editorial, EnrichedCarousel, Hero, HighlightedTitle } from './components'
import { Seo } from './Seo'

type HomeProps = { homepage: entities.boutiqueHomepage.BoutiqueHomepage }

export function Home({ homepage }: HomeProps): React.ReactElement {
  return (
    <>
      <Seo />
      <Hero heroData={homepage.heroData} />

      <SectionWrapper>
        {homepage.discount.length > 0 && (
          <EnrichedCarousel
            list={homepage.discount}
            title={
              <plume.styles.heading.L asDesktop="XL">
                Les{' '}
                <HighlightedTitle color="PRIMARY_RED" bgColor="SECONDARY_RED_LIGHT">
                  bons plans
                </HighlightedTitle>{' '}
                <br />
                des marques engagées
              </plume.styles.heading.L>
            }
            cta={
              <CenteredCta>
                <Link href={url.products({ query: { facetFilters: { deal: true } } }).path}>
                  <plume.Button size="medium">Voir tous les bons plans</plume.Button>
                </Link>
              </CenteredCta>
            }
          />
        )}

        <Editorial />

        {homepage.selection && homepage.selection.length > 0 && (
          <EnrichedCarousel
            hasBackground
            list={homepage.selection}
            title={
              <plume.styles.heading.L asDesktop="XL">
                La{' '}
                <HighlightedTitle color="PRIMARY_BLUE_HOVER" bgColor="BLUE_SHADE_3">
                  sélection
                </HighlightedTitle>{' '}
                <br />
                du moment
              </plume.styles.heading.L>
            }
            cta={
              <CenteredCta>
                <Link href={url.products({}).path}>
                  <plume.Button size="medium">Voir tous les produits</plume.Button>
                </Link>
              </CenteredCta>
            }
          />
        )}

        {homepage.popular.length > 0 && (
          <EnrichedCarousel
            list={homepage.popular}
            title={
              <plume.styles.heading.L asDesktop="XL">
                Les{' '}
                <HighlightedTitle color="SECONDARY_MINT" bgColor="SECONDARY_GREEN_LIGHT">
                  incontournables
                </HighlightedTitle>{' '}
              </plume.styles.heading.L>
            }
          />
        )}

        {homepage.new.length > 0 && (
          <EnrichedCarousel
            hasBackground
            list={homepage.new}
            title={
              <plume.styles.heading.L asDesktop="XL">
                Les{' '}
                <HighlightedTitle color="PRIMARY_RED" bgColor="SECONDARY_RED_LIGHT">
                  nouveautés
                </HighlightedTitle>{' '}
              </plume.styles.heading.L>
            }
          />
        )}
      </SectionWrapper>
    </>
  )
}

const CenteredCta = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
`

const SectionWrapper = styled.section`
  overflow: hidden;
  padding: 0 20px;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    padding: 0 35px;
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    padding: 0 45px;
  }
`
