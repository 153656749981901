import * as React from 'react'
import { JsonLdBase, type JsonLd } from './JsonLd'

type WebSiteJsonLd = {
  name: string
  url: string
  alternateName?: string
}

export type WebSiteProps = {
  jsonld: WebSiteJsonLd
  scriptKey?: string
}

export function WebSite({ jsonld, scriptKey }: WebSiteProps) {
  const completeJsonLd: JsonLd<'WebSite'> = { ...jsonld, '@type': 'WebSite' }
  return <JsonLdBase jsonld={completeJsonLd} scriptKey={scriptKey} />
}
