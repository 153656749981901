import * as React from 'react'
import { NextSeo } from 'next-seo'
import Head from 'next/head'
import * as seo from '@owl-nest/seo'

import { SITE_URL, FACEBOOK_APP_ID, SITE_TWITTER_HANDLE } from '@boutique/config'

const title = 'Ulule Boutique - La boutique des marques engagées'
const description =
  'La marketplace qui sélectionne des produits fabriqués par des marques engagées, dans le respect de la planète et des humains. Consommez moins, consommez mieux !'

export function Seo(): React.ReactElement {
  return (
    <>
      <NextSeo
        title={title}
        description={description}
        facebook={{
          appId: FACEBOOK_APP_ID,
        }}
        twitter={{
          site: SITE_TWITTER_HANDLE,
          cardType: 'summary_large_image',
        }}
        openGraph={{
          title,
          type: 'website',
          description,
          url: SITE_URL,
          images: [
            {
              alt: 'TITLE',
              url: SITE_URL + '/assets/images/Ulule_Boutique.png',
            },
          ],
        }}
        robotsProps={{
          maxImagePreview: 'large',
        }}
      />
      <Head>
        <seo.jsonld.WebSite
          jsonld={{
            name: 'Ulule Boutique',
            url: SITE_URL,
          }}
        />
      </Head>
    </>
  )
}
