import * as next from 'next'

import * as entities from '@boutique/entities'
import * as services from '@boutique/services'

export { Home as default } from '@boutique/homepage'

export async function getStaticProps(): Promise<
  next.GetStaticPropsResult<{ homepage: entities.boutiqueHomepage.BoutiqueHomepage }>
> {
  const homepage = await services.homepage.get()

  return {
    props: { homepage },
    revalidate: 10,
  }
}
