import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

import { SliderButton } from './button/SliderButton'

export type CarouselProps = React.HTMLAttributes<HTMLDivElement> & {
  isPrevDisabled?: boolean
  isNextDisabled?: boolean
  fixedControls?: boolean
  hideControls?: boolean
  onCountChange?: (newCount: number) => void
}

function CarouselComponent({
  children,
  isPrevDisabled,
  isNextDisabled,
  fixedControls,
  hideControls,
  onCountChange,
  ...rest
}: CarouselProps): React.ReactElement {
  const [count, setCount] = React.useState(0)
  const [animation, setAnimation] = React.useState('fade')
  const slides = React.Children.toArray(children)
  const slidesLength = slides.length

  React.useEffect(() => {
    if (onCountChange) {
      onCountChange(count)
    }
  }, [count, onCountChange])

  return (
    <S.carousel.Wrapper {...rest}>
      {!hideControls && (
        <S.carousel.ControlPrev fixedControls={fixedControls}>
          <SliderButton
            direction="left"
            disabled={isPrevDisabled}
            onClick={() => {
              setAnimation('prev')
              count === 0 ? setCount(slidesLength - 1) : setCount(count - 1)
            }}
          />
        </S.carousel.ControlPrev>
      )}
      <S.carousel.Slide data-animation={animation}>{slides[count]}</S.carousel.Slide>
      {!hideControls && (
        <S.carousel.ControlNext fixedControls={fixedControls}>
          <SliderButton
            direction="right"
            disabled={isNextDisabled}
            onClick={() => {
              setAnimation('next')
              count === slidesLength - 1 ? setCount(0) : setCount(count + 1)
            }}
          />
        </S.carousel.ControlNext>
      )}
    </S.carousel.Wrapper>
  )
}

export const Carousel = styled(CarouselComponent)``
